<p-confirmDialog [style]="{width: '432px'}" [baseZIndex]="10000"
        rejectButtonStyleClass="p-button-text"
        acceptButtonStyleClass="p-button-rounded p-button-success"
        acceptLabel="Sim"
        rejectLabel="Não"
        acceptIcon="none"
        rejectIcon="none">
</p-confirmDialog>
<p-dialog [header]="alertHeader" [(visible)]="displayDialog" [style]="{width: '50vw'}" [baseZIndex]="10000">
    <p>{{ alertDescription }}</p>
    <ng-template pTemplate="footer">
        <p-button icon="pi pi-check" (click)="closeAlert()" label="Ok" styleClass="p-button-text"></p-button>
    </ng-template>
</p-dialog>

<p-dialog header="Alterar" [(visible)]="displaySelectDialog" [style]="{width: '50vw'}" [baseZIndex]="10000">
    <div *ngIf="reloaddSelectComponent">
        <app-select-company (selectEvent)="selectValues($event)"></app-select-company>
    </div>
    <ng-template pTemplate="footer">
        <p-button (click)="onCancelSelectValues()" label="Cancelar" styleClass="p-button-text"></p-button>
        <p-button (click)="confirmSelectValues()" label="Confirmar" styleClass="p-button-rounded p-button-primary"></p-button>
    </ng-template>
</p-dialog>

<p-toast></p-toast>

<div *ngIf="!editingEmployeeConfiguration">
    <div class="row body-row">
        <div class="col-sm body-row-fields">
            <h3>Empresa/Filial</h3>
            <span>{{ companyName }}</span>
        </div>

        <div class="col-sm body-row-fields">
            <h3>Departamento</h3>
            <span>{{ departmentName }}</span>
        </div>

        <div class="col-sm body-row-fields"></div>
    </div>

    <div class="row body-row">
        <div class="col-sm body-row-fields">
            <h3>Cargo</h3>
            <span>{{ companiesPositionSelect || 'Não cadastrado' }}</span>
        </div>
        <div class="col-sm body-row-fields">
            <h3>É Gestor(a)?</h3>
            <span>{{ contract.role === 'WORKER' ? 'Não é gestor' : (contract.role === 'MANAGER' ? 'Da empresa' : 'Do departamento') }}</span>
        </div>
        <div class="col-sm body-row-fields">
            <h3>Regime</h3>
            <span *ngIf="contract?.homeOffice">Home Office</span>
            <span *ngIf="contract?.flexibleJourney">Jornada Híbrida</span>
            <span *ngIf="contract?.headOffice">Padrão</span>
            <span *ngIf="contract?.branchAddress">Filial</span>
            <span *ngIf="contract?.skipAllValidation">Ponto Livre</span>
            <div *ngIf="!contract?.homeOffice && !contract?.flexibleJourney && !contract?.headOffice && !contract?.branchAddress && !contract?.skipAllValidation">
                <span>Não cadastrado</span>
            </div>
        </div>
    </div>
    <div class="row body-row">
        <div class="col-sm body-row-fields">
            <h3>Dias de trabalho</h3>

            <div *ngFor="let item of journeyText; let i = index">
                <span>{{ item }}</span>
            </div>
        </div>
        <div class="col-sm body-row-fields"></div>
    </div>

    <div class="button-save-container d-flex justify-content-center align-items-center  flex-column">
        <button type="submit" class="btn btn-outline-primary btn-block" (click)="onEditingConfigureEmployee()" *ngIf="contract?.status === 'ACTIVE'">
            <div class="d-flex justify-content-center">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-0" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
                </svg>
                <span class="ml-2">Editar</span>
            </div>
        </button>
    </div>
</div>

<form [formGroup]="formConfig" *ngIf="editingEmployeeConfiguration">
    <div class="update-form">
        <!-- ROW-01 -->
        <div class="form-row">
            <div class="form-group col-lg-6">
                <app-select-company [initialValues]="formConfig"  (selectEvent)="selectValues($event)"></app-select-company>
            </div>

            <div class="form-group col-lg-3">
                <label for="companiesPosition">Departamento *</label>
                <div *ngIf="!departments || departments.length === 0; else departmentSelect">
                    <div class="spinner-grow text-primary" role="status">
                        <span class="sr-only">Carregando...</span>
                    </div>
                </div>

                <ng-template #departmentSelect>
                    <select class="form-control w-100" id="companiesPosition" formControlName="departmentId"
                            style="width: 50%;"
                            [ngClass]="{'is-invalid': formConfig.get('departmentId')?.hasError('required') && formConfig.get('departmentId')?.touched}"
                    >
                        <option value="">Selecione um Departamento</option>
                        <option *ngFor="let department of departments" value="{{department.id}}">
                            {{department.name}}
                        </option>
                    </select>
                </ng-template>

                <div class="custom-invalid-feedback" *ngIf="formConfig.get('departmentId')?.hasError('required')
                    && formConfig.get('departmentId')?.touched">
                    Departamento é requerido
                </div>
            </div>

           <div class="form-group col-lg-3">
               <label for="companiesPosition">Cargo *</label>
               <div class="d-flex justify-content-start align-items-center">
                <select class="form-control" id="cargo" formControlName="companyPositionId" (change)="onChange($event)"
                           style="width: 50%;"
                           [ngClass]="{
                               'is-invalid': formConfig.get('companyPositionId')?.hasError('required') && formConfig.get('companyPositionId')?.touched
                           }">
                       <option value="">Selecione um cargo</option>
                       <option *ngFor="let item of companiesPositions"
                               value="{{item.id}}">{{item.name}}</option>
                   </select>
               </div>
           </div>
        </div>

        <!-- Managr and Regime -->
        <div class="form-row" [hidden]="employee.accountType === 'ACCOUNT_HOLDER'">
            <div class="form-group col-lg-6">
                <label>É Gestor(a)?</label>
                <div class="d-flex mt-2">
                    <div class="form-check mr-3">
                        <input class="form-check-input"
                               formControlName="role"
                               type="radio"
                               name="role"
                               id="roleTrue"
                               value="MANAGER"
                               (change)="changeRole($event.target)">
                        <label class="form-check-label" for="roleTrue">
                            Da empresa
                        </label>
                    </div>
                    <div class="form-check mr-3">
                        <input class="form-check-input"
                               formControlName="role"
                               type="radio"
                               name="role"
                               id="roleManager"
                               value="WORKER"
                               (change)="changeRole($event.target)">
                        <label for="roleManager">
                            Não é gestor
                        </label>
                    </div>
                    <div class="form-check">
                        <input class="form-check-input"
                               formControlName="role"
                               type="radio"
                               name="role"
                               id="roleDepartment"
                               value="DEPARTAMENT_MANAGER"
                               (change)="changeRole($event.target)">
                        <label for="roleDepartment">
                            Do departamento
                        </label>
                    </div>
                </div>
            </div>
        </div>

        <div class="form-row">
            <div class="form-group col-lg-12">
                <label>Regime</label>
                <div class="d-flex justify-content-start mt-2">
                    <div class="form-check mr-3">
                        <input class="form-check-input"
                               formControlName="homeOffice"
                               type="radio"
                               id="regime"
                               value="true"
                               (change)="homeOfficeToggleChange($event.target)">
                        <label for="regime">
                            Home Office
                        </label>
                    </div>

                    <div class="form-check mr-3">
                        <input class="form-check-input"
                               formControlName="flexibleJourney"
                               type="radio"
                               id="flexible"
                               value="true"
                               (change)="flexibleJourneyToggleChange($event.target)">
                        <label for="flexible">
                            Jornada Híbrida
                        </label>
                    </div>

                    <div class="form-check mr-3">
                        <input class="form-check-input"
                               formControlName="headOffice"
                               type="radio"
                               id="headOffice"
                               value="true"
                               (change)="headOfficeToggleChange($event.target)">
                        <label for="flexible">
                            Padrão
                        </label>
                    </div>

                    <div class="form-check mr-3" *ngIf="thereIsBranc">
                        <input class="form-check-input"
                               formControlName="branchAddress"
                               type="radio"
                               id="branchAddress"
                               value="true"
                               (change)="branchAddressToggleChange($event.target)">
                        <label for="flexible">
                            Filial
                        </label>
                    </div>

                    <div class="form-check mr-3">
                        <input class="form-check-input"
                               formControlName="skipAllValidation"
                               type="radio"
                               id="skipAllValidation"
                               value="true"
                               (change)="skipAllValidationToggleChange($event.target)">
                        <label for="flexible">
                            Ponto Livre
                        </label>
                    </div>
                </div>
            </div>
        </div>

        <!-- ROW-02 -->
        <div class="form-row">
            <div class="form-group col-lg-12">
                <label>Dias de trabalho</label>

                <div class="mb-3">
                    <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" name="journeyOptionRadio" id="inlineRadio1" checked
                         formControlName="journeyOptionRadio" [value]="1" (change)="selectedJourneyOption = 1; changeJourgeType();">
                        <label class="form-check-label" for="inlineRadio1">Configurar jornada por semana</label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" name="journeyOptionRadio" id="inlineRadio2"
                            formControlName="journeyOptionRadio" [value]="2" (change)="selectedJourneyOption = 2; changeJourgeType();">
                        <label class="form-check-label" for="inlineRadio2">Configurar jornada por dia</label>
                    </div>
                </div>

                <div class="journey-container p-3">
                    <div *ngIf="selectedJourneyOption === 2; else byWeek" class="div-fade-in">
                        <div *ngIf="contract!.journey !== undefined">
                            <app-journey-by-day
                                [contract]="contract"
                                (journeyChange)="updateJourney($event)"
                                (updateFormStatus)="updateJoruneyDayFormStatus($event)">
                            </app-journey-by-day>
                        </div>
                        <div *ngIf="contract!.journey === undefined">
                            <app-journey-by-day
                                [company]="company"
                                (journeyChange)="updateJourney($event)"
                                (updateFormStatus)="updateJoruneyDayFormStatus($event)">
                            </app-journey-by-day>
                        </div>
                    </div>

                    <ng-template #byWeek>
                        <div class="alert alert-warning animated--fade-in" role="alert" *ngIf="showAttentionMessage() && activeJourney">
                            <strong>ATENÇÃO: </strong>Não podem conter campos vazios ou com 00:00.
                        </div>
                        <div class="alert alert-warning animated--fade-in" role="alert" *ngIf="!activeJourney && journeyType === 'week'">
                            <strong>ATENÇÃO: </strong>Deve existir pelo menos um dia da semana selecionado.
                        </div>
                        <div class="p-3 div-fade-in">
                            <div class="day-weeks-container d-flex justify-content-between">
                                <div class="btn-day d-flex align-items-center">
                                    <p-inputSwitch formControlName="enabledMonday"
                                                   inputId="monday" (ngModelChange)="createJourney()"></p-inputSwitch>
                                    <label class="mb-1">Seg</label>
                                </div>
                                <div class="btn-day d-flex align-items-center">
                                    <p-inputSwitch formControlName="enabledTuesday"
                                                   inputId="tuesday" (ngModelChange)="createJourney()"></p-inputSwitch>
                                    <label class="mb-1">Ter</label>
                                </div>
                                <div class="btn-day d-flex align-items-center">
                                    <p-inputSwitch formControlName="enabledWednesday"
                                                   inputId="wednesday" (ngModelChange)="createJourney()"></p-inputSwitch>
                                    <label class="mb-1">Qua</label>
                                </div>
                                <div class="btn-day d-flex align-items-center">
                                    <p-inputSwitch formControlName="enabledThursday"
                                                   inputId="thursday" (ngModelChange)="createJourney()"></p-inputSwitch>
                                    <label class="mb-1">Qui</label>
                                </div>
                                <div class="btn-day d-flex align-items-center">
                                    <p-inputSwitch formControlName="enabledFriday"
                                                   inputId="friday" (ngModelChange)="createJourney()"></p-inputSwitch>
                                    <label class="mb-1">Sex</label>
                                </div>
                                <div class="btn-day d-flex align-items-center">
                                    <p-inputSwitch formControlName="enabledSaturday"
                                                   inputId="saturday" (ngModelChange)="createJourney()"></p-inputSwitch>
                                    <label class="mb-1">Sab</label>
                                </div>
                                <div class="btn-day d-flex align-items-center">
                                    <p-inputSwitch formControlName="enabledSunday"
                                                   inputId="sunday" (ngModelChange)="createJourney()"></p-inputSwitch>
                                    <label class="mb-1">Dom</label>
                                </div>
                            </div>

                            <div class="form-row period-container">
                                <div class="form-group col-lg-12" style="margin-bottom: 0px; margin-top: 20px;">
                                    <label class="row-label">
                                        Período
                                    </label>
                                </div>
                                <div class="form-group col-lg-3">
                                    <div class="p-field mr-4">
                                        <label class="label-period" for="startTimeFirstPeriod">Início Primeiro Período *</label>
                                        <p-inputMask class="p-inputtext-sm" id="startTimeFirstPeriod" placeholder="Ex: 00:00" mask="99:99"
                                            formControlName="startTimeFirstPeriod"
                                            (ngModelChange)="createJourney()"
                                            [ngClass]="{
                                                'is-invalid': formConfig.get('startTimeFirstPeriod')?.hasError('required') && formConfig.get('startTimeFirstPeriod')?.touched}">
                                        </p-inputMask>
                                        <div class="invalid-feedback" *ngIf="formConfig.get('startTimeFirstPeriod')?.hasError('required')
                                            && formConfig.get('startTimeFirstPeriod')?.touched">
                                            Campo requerido
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group col-lg-3">
                                    <label class="label-period" for="endTimeFirstPeriod">Fim Primeiro Período *</label>
                                    <div class="p-field mr-4">
                                        <p-inputMask class="p-inputtext-sm" id="endTimeFirstPeriod" placeholder="Ex: 00:00" mask="99:99"
                                            formControlName="endTimeFirstPeriod"
                                            (ngModelChange)="createJourney()"
                                            [ngClass]="{
                                                'is-invalid': formConfig.get('endTimeFirstPeriod')?.hasError('required') && formConfig.get('endTimeFirstPeriod')?.touched}">
                                        </p-inputMask>
                                        <div class="invalid-feedback" *ngIf="formConfig.get('endTimeFirstPeriod')?.hasError('required')
                                            && formConfig.get('endTimeFirstPeriod')?.touched">
                                            Campo requerido
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group col-lg-3">
                                    <label class="label-period" for="startTimeSecondPeriod">Início Segundo Período *</label>
                                    <div class="p-field mr-4">
                                        <p-inputMask class="p-inputtext-sm" id="startTimeSecondPeriod" placeholder="Ex: 00:00" mask="99:99"
                                            formControlName="startTimeSecondPeriod"
                                            (ngModelChange)="createJourney()"
                                            [ngClass]="{
                                                'is-invalid': formConfig.get('startTimeSecondPeriod')?.hasError('required') && formConfig.get('startTimeSecondPeriod')?.touched}">
                                        </p-inputMask>
                                        <div class="invalid-feedback" *ngIf="formConfig.get('startTimeSecondPeriod')?.hasError('required')
                                            && formConfig.get('startTimeSecondPeriod')?.touched">
                                            Campo requerido
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group col-lg-3">
                                    <label class="label-period" for="endTimeSecondPeriod">Fim Segundo Período *</label>
                                    <div class="p-field">
                                        <p-inputMask class="p-inputtext-sm" id="endTimeSecondPeriod" placeholder="Ex: 00:00" mask="99:99"
                                            formControlName="endTimeSecondPeriod"
                                            (ngModelChange)="createJourney()"
                                            [ngClass]="{
                                                'is-invalid': formConfig.get('endTimeSecondPeriod')?.hasError('required') && formConfig.get('endTimeSecondPeriod')?.touched}">
                                        </p-inputMask>
                                        <div class="invalid-feedback" *ngIf="formConfig.get('endTimeSecondPeriod')?.hasError('required')
                                            && formConfig.get('endTimeSecondPeriod')?.touched">
                                            Campo requerido
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </div>

            </div>
        </div>

        <div class="d-flex justify-content-center flex-column m-2">
            <button type="button" class="btn btn-primary btn-block" *ngIf="journeyType === 'week'" (click)="updateContract()" [disabled]="!formConfig.valid || !formStatus || (showAttentionMessage() && activeJourney)">
                <div class="d-flex justify-content-center">
                    <span class="ml-2">Salvar</span>
                </div>
            </button>
            <button type="button" class="btn btn-primary btn-block" *ngIf="journeyType === 'day'" (click)="updateContract()" [disabled]="!formJourneyDayStatus">
                <div class="d-flex justify-content-center">
                    <span class="ml-2">Salvar</span>
                </div>
            </button>
            <button type="button" class="btn btn-link mr-2 btn-block" (click)="onCancel()">
                <div class="d-flex justify-content-center">
                    <span class="ml-2">Cancelar</span>
                </div>
            </button>
        </div>
    </div>
</form>

