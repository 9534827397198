import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AbstractControl, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import { Store } from '@ngrx/store';
import * as moment from 'moment';
import {ConfirmationService, MessageService} from 'primeng/api';
import {DialogService, DynamicDialogRef} from 'primeng/dynamicdialog';
import { Company } from 'src/model/company';
import HttpCompanyPositionResponse, {CompanyPosition} from 'src/model/company-position';
import {Contract} from 'src/model/contract';
import {Department} from 'src/model/department';
import HttpEmployeeResponse, {Employee} from 'src/model/employee';
import {Occupation} from 'src/model/occupation';
import {AppStorageService} from 'src/services/app-storage.service';
import {CompanyPositionService} from 'src/services/company-position.service';
import {CompanyService} from 'src/services/company.service';
import {ContractService} from 'src/services/contract.service';
import {DepartmentService} from 'src/services/department.service';
import {EmployeeService} from 'src/services/employee.service';
import {EventEmitterService} from 'src/services/event-emitter.service';
import { ValidateDatesService } from 'src/services/validate-dates.service';
import { EmployeeState } from 'src/stores/states/employee.state';
import * as EmployeeAction from '../../stores/actions/employee.action';

type Period = 'day' | 'week';

@Component({
  selector: 'app-employee-configuration',
  templateUrl: './employee-configuration.component.html',
  styleUrls: ['./employee-configuration.component.scss'],
  providers: [MessageService, ConfirmationService, DialogService]
})
export class EmployeeConfigurationComponent implements OnInit {

  @Input() contract: Contract = new Contract;
  @Input() employee: Employee = new Employee;
  @Input() company: Company = new Company;
  @Output() cancelUpdate = new EventEmitter<boolean>();

  public formConfig: FormGroup;
  public date: string;
  public occupation?: string;
  public occupations?: Occupation[];
//   public company!: Company | undefined;
  public companiesPositions: CompanyPosition[] = [];
  public departments: Department[] = [];
  public departmentName: string = '';
  public companiesPositionSelect?: string;
  public companiesPosition?: string;
  public displayDialog = false;
  public displaySelectDialog = false;
  public alertHeader = '';
  public alertDescription = '';
  public loadSkeleton!: boolean;
  public checkBrachAddress = false;
  public editingEmployeeConfiguration = false;
  public workDays = [];
  public ref: DynamicDialogRef = new DynamicDialogRef();
  public companyIdTemp = '';
  public pathTemp = '';
  public reloaddSelectComponent = true;
  public thereIsBranc = false;
  public formJourneyDayStatus = false;
  public activeJourney = false;
  public formStatus = false;
  public companyName: any = ''
  public selectedJourneyOption = 1;
  public journeyType: Period = 'week';
  public journeyText: any[] = [];
  public journeyByDay: any = {}
  public journeyByWeek: any = {};

  constructor(
    private companyPositionService: CompanyPositionService,
    private contractService: ContractService,
    private departmentService: DepartmentService,
    private employeeService: EmployeeService,
    private appStorageService: AppStorageService,
    private companyService: CompanyService,
    private formBuilder: FormBuilder,
    private messageService: MessageService,
    private dialogService: DialogService,
    private confirmationService: ConfirmationService,
    private store: Store<EmployeeState>,
    private validateDateService: ValidateDatesService,
  ) {
    const now = moment();
    this.date = now.format();
    this.formConfig = this.formBuilder.group({
      role: [''],
      companyPositionId: ['', Validators.required],
      companyId: ['', Validators.required],
      departmentId: ['', Validators.required],
      path: [''],
      enabledSunday: [false],
      enabledMonday: [false],
      enabledTuesday: [false],
      enabledWednesday: [false],
      enabledThursday: [false],
      enabledFriday: [false],
      enabledSaturday: [false],
      startTimeFirstPeriod: [''],
      endTimeFirstPeriod: [''],
      startTimeSecondPeriod: [''],
      endTimeSecondPeriod: [''],
      amountOfHours: [''],
      journeyOptionRadio:[''],
      branchAddress: [false],
      homeOffice: [false],
      flexibleJourney: [false],
      headOffice: [false],
      skipAllValidation: [false],
      branchId: [''],
    });
    this.formConfig.patchValue({
      enabledSunday: false,
      enabledMonday: false,
      enabledTuesday: false,
      enabledWednesday: false,
      enabledThursday: false,
      enabledFriday: false,
      enabledSaturday: false,
    });

    this.formConfig.get('enabledMonday')!.valueChanges.subscribe((enabled) => {
        this.updatePeriodsValidation(enabled);
    });
    this.formConfig.get('enabledTuesday')!.valueChanges.subscribe((enabled) => {
        this.updatePeriodsValidation(enabled);
    });
    this.formConfig.get('enabledWednesday')!.valueChanges.subscribe((enabled) => {
        this.updatePeriodsValidation(enabled);
    });
    this.formConfig.get('enabledThursday')!.valueChanges.subscribe((enabled) => {
        this.updatePeriodsValidation(enabled);
    });
    this.formConfig.get('enabledFriday')!.valueChanges.subscribe((enabled) => {
        this.updatePeriodsValidation(enabled);
    });
    this.formConfig.get('enabledSaturday')!.valueChanges.subscribe((enabled) => {
        this.updatePeriodsValidation(enabled);
    });
    this.formConfig.get('enabledSunday')!.valueChanges.subscribe((enabled) => {
        this.updatePeriodsValidation(enabled);
    });
  }

  public openAlert(header: string, description: string): void {
    this.alertHeader = header;
    this.alertDescription = description;
    this.displayDialog = true;
  }

  public closeAlert(): void {
    this.alertHeader = '';
    this.alertDescription = '';
    this.displayDialog = false;
  }

  async ngOnInit(): Promise<void> {
    this.companyName = ''

    const company = this.company;
    this.thereIsBranc = await this.companyService.thereIsBranch(company.id!, company.type!);

    this.loadSkeleton = true;
    await this.initializePage();
  }

  async initializePage(): Promise<void> {
    const path =  this.employee!.path;
    const companyId = path!.split('#', 1);

    await this.getCompanyPosition(companyId[0]);
    this.organizedWeekdays();

    if (this.contract!.journey !== undefined) {
        if (this.contract.journeyType === 'week') {
            this.journeyType = 'week'
            this.selectedJourneyOption = 1;
            this.journeyByWeek = JSON.parse(this.contract!.journey!)
            this.formConfig.get('journeyOptionRadio')!.setValue(1);

            const journeyByWeek: { [key: string]: string[] } = {};

            for (let i = 1; i <= 7; i++) {
                journeyByWeek[i.toString()] = this.journeyByWeek[i.toString()].split('-');
            }

            this.formConfig.patchValue({
                enabledSunday: JSON.parse(journeyByWeek['7'][4]),
                enabledMonday: JSON.parse(journeyByWeek['1'][4]),
                enabledTuesday: JSON.parse(journeyByWeek['2'][4]),
                enabledWednesday: JSON.parse(journeyByWeek['3'][4]),
                enabledThursday: JSON.parse(journeyByWeek['4'][4]),
                enabledFriday: JSON.parse(journeyByWeek['5'][4]),
                enabledSaturday: JSON.parse(journeyByWeek['6'][4]),
                startTimeFirstPeriod: journeyByWeek['1'][0],
                endTimeFirstPeriod: journeyByWeek['1'][1],
                startTimeSecondPeriod: journeyByWeek['1'][2],
                endTimeSecondPeriod: journeyByWeek['1'][3],
            });
            this.createJourneyText(this.journeyByWeek);
            this.checkIfOneAnableExists();
        } else {
            this.journeyType = 'day'
            this.selectedJourneyOption = 2;
            this.formConfig.reset();
            this.formConfig.get('journeyOptionRadio')!.setValue(2);
            this.journeyByDay = JSON.parse(this.contract!.journey!)

            const journeyByday: { [key: string]: string[] } = {};

            for (let i = 1; i <= 7; i++) {
                journeyByday[i.toString()] = this.journeyByDay[i.toString()].split('-');
            }

            this.formConfig.patchValue({
                enabledSunday: false,
                enabledMonday: false,
                enabledTuesday: false,
                enabledWednesday: false,
                enabledThursday: false,
                enabledFriday: false,
                enabledSaturday: false,
            });

            this.createJourneyText(this.journeyByDay);
        }
    } else {
      if (this.company.journeyType === 'week') {
        this.journeyType = 'week'
        this.selectedJourneyOption = 1;
        this.journeyByWeek = JSON.parse(this.company!.journey!)
        this.formConfig.get('journeyOptionRadio')!.setValue(1);

        const journeyByWeek: { [key: string]: string[] } = {};

        for (let i = 1; i <= 7; i++) {
            journeyByWeek[i.toString()] = this.journeyByWeek[i.toString()].split('-');
        }

        this.formConfig.patchValue({
            enabledSunday: JSON.parse(journeyByWeek['7'][4]),
            enabledMonday: JSON.parse(journeyByWeek['1'][4]),
            enabledTuesday: JSON.parse(journeyByWeek['2'][4]),
            enabledWednesday: JSON.parse(journeyByWeek['3'][4]),
            enabledThursday: JSON.parse(journeyByWeek['4'][4]),
            enabledFriday: JSON.parse(journeyByWeek['5'][4]),
            enabledSaturday: JSON.parse(journeyByWeek['6'][4]),
            startTimeFirstPeriod: journeyByWeek['1'][0],
            endTimeFirstPeriod: journeyByWeek['1'][1],
            startTimeSecondPeriod: journeyByWeek['1'][2],
            endTimeSecondPeriod: journeyByWeek['1'][3],
        });
        this.checkIfOneAnableExists();
      } else {
          this.journeyType = 'day'
          this.selectedJourneyOption = 2;
          this.formConfig.reset();
          this.formConfig.get('journeyOptionRadio')!.setValue(2);
          this.journeyByDay = JSON.parse(this.company!.journey!)

          const journeyByday: { [key: string]: string[] } = {};

          for (let i = 1; i <= 7; i++) {
              journeyByday[i.toString()] = this.journeyByDay[i.toString()].split('-');
          }

          this.formConfig.patchValue({
              enabledSunday: false,
              enabledMonday: false,
              enabledTuesday: false,
              enabledWednesday: false,
              enabledThursday: false,
              enabledFriday: false,
              enabledSaturday: false,
          });
      }
    }

    if (this.contract) {
      this.formConfig.get('companyPositionId')?.setValue(this.contract.companyPositionId);
      this.formConfig.get('companyId')?.setValue(this.contract.companyId);
      this.formConfig.get('departmentId')?.setValue(this.contract.departmentId);
      this.formConfig.get('path')?.setValue(this.contract.path);

      if (this.contract.branchId) {
          this.formConfig.get('branchId')?.setValue(this.contract.branchId);
      }

      if (this.companiesPositionSelect === undefined) {
        this.formConfig.get('companyPositionId')?.setValue('');
      }

      const regime = this.contract.homeOffice && this.contract.flexibleJourney && this.contract.headOffice &&this.contract.branchAddress;

      if (this.contract.role === 'MANAGER' && regime === undefined) {
        if (this.company.homeOffice === true) {
            this.formConfig.get('homeOffice')?.setValue('true');
          }

          if (this.company.flexibleJourney === true) {
            this.formConfig.get('flexibleJourney')?.setValue('true');
          }

          if (this.company.headOffice === true) {
            this.formConfig.get('headOffice')?.setValue('true');
          }

          if (this.company.branchAddress === true) {
            this.formConfig.get('branchAddress')?.setValue('true');
          }

          if (this.company.skipAllValidation === true) {
            this.formConfig.get('skipAllValidation')?.setValue('true');
          }
      } else {
          if (this.contract.homeOffice === true) {
            this.formConfig.get('homeOffice')?.setValue('true');
          }

          if (this.contract.flexibleJourney === true) {
            this.formConfig.get('flexibleJourney')?.setValue('true');
          }

          if (this.contract.headOffice === true) {
            this.formConfig.get('headOffice')?.setValue('true');
          }

          if (this.contract.branchAddress === true) {
            this.formConfig.get('branchAddress')?.setValue('true');
          }

          if (this.contract.skipAllValidation === true) {
            this.formConfig.get('skipAllValidation')?.setValue('true');
          }
      }


      this.formConfig.get('role')?.setValue(this.contract.role);
      this.formConfig.get('companyPositionId')?.setValue(this.contract.companyPositionId);
      await this.getDepartments(this.contract.companyId!);
      await this.getCurrentDepartmentName(
          this.contract.branchId ? this.contract.branchId! : this.contract.companyId!
      );
      if (this.contract.branchId) {
          const company: any = await this.companyService.get(this.contract.branchId).toPromise();
          this.companyName = company.data.name;
      } else {
          const company: any = await this.companyService.get(this.contract.companyId).toPromise();
          this.companyName = company.data.name;
      }

      this.loadSkeleton = false;
    }
  }

  createJourney() {
    this.journeyByWeek = {};
    this.checkIfOneAnableExists();
    const enabledSunday = this.formConfig.get(`enabledSunday`)?.value;
    const enabledMonday = this.formConfig.get(`enabledMonday`)?.value;
    const enabledTuesday = this.formConfig.get(`enabledTuesday`)?.value;
    const enabledWednesday = this.formConfig.get(`enabledWednesday`)?.value;
    const enabledThursday = this.formConfig.get(`enabledThursday`)?.value;
    const enabledFriday = this.formConfig.get(`enabledFriday`)?.value;
    const enabledSaturday = this.formConfig.get(`enabledSaturday`)?.value;
    this.journeyByWeek[1] = this.getJourneyString(this.formConfig, enabledMonday);
    this.journeyByWeek[2] = this.getJourneyString(this.formConfig, enabledTuesday);
    this.journeyByWeek[3] = this.getJourneyString(this.formConfig, enabledWednesday);
    this.journeyByWeek[4] = this.getJourneyString(this.formConfig, enabledThursday);
    this.journeyByWeek[5] = this.getJourneyString(this.formConfig, enabledFriday);
    this.journeyByWeek[6] = this.getJourneyString(this.formConfig, enabledSaturday);
    this.journeyByWeek[7] = this.getJourneyString(this.formConfig, enabledSunday);
  }

  getJourneyString(formGroup: any, isAnabled: boolean) {
    let isEnabled = isAnabled;
    const startTimeFirstPeriod = formGroup.get(`startTimeFirstPeriod`).value;
    const endTimeFirstPeriod = formGroup.get(`endTimeFirstPeriod`).value;
    const startTimeSecondPeriod = formGroup.get(`startTimeSecondPeriod`).value;
    const endTimeSecondPeriod = formGroup.get(`endTimeSecondPeriod`).value;

    return `${startTimeFirstPeriod}-${endTimeFirstPeriod}-${startTimeSecondPeriod}-${endTimeSecondPeriod}-${isEnabled}`;
  }

  createJourneyText(data: any) {
    const daysOfWeek = ['SEG', 'TER', 'QUA', 'QUI', 'SEX', 'SAB', 'DOM'];
    const resultArray = [];
    for (const key in data) {
        if (data.hasOwnProperty(key) && data[key].endsWith("-true")) {
            const times = data[key].split('-');
            const dayOfWeek = daysOfWeek[parseInt(key)-1];
            const timeRanges = times.slice(0, -1).join('-');
            const resultString = `${dayOfWeek} (${timeRanges})`;
            resultArray.push(resultString);
        }
    }

    this.journeyText = resultArray;
  }

  updateJourney(newJourney: any) {
    this.journeyByDay = newJourney;
  }

  changeJourgeType() {
    if (this.selectedJourneyOption === 1) {
        this.journeyType = 'week';
        this.formJourneyDayStatus = false;
        this.createJourney();
        this.formConfig.get(`enabledSunday`)?.setValue(false);
        this.formConfig.get(`enabledMonday`)?.setValue(false);
        this.formConfig.get(`enabledTuesday`)?.setValue(false);
        this.formConfig.get(`enabledWednesday`)?.setValue(false);
        this.formConfig.get(`enabledThursday`)?.setValue(false);
        this.formConfig.get(`enabledFriday`)?.setValue(false);
        this.formConfig.get(`enabledSaturday`)?.setValue(false);
        this.formConfig.get(`startTimeFirstPeriod`)!.setValue('00:00');
        this.formConfig.get(`endTimeFirstPeriod`)!.setValue('00:00');
        this.formConfig.get(`startTimeSecondPeriod`)!.setValue('00:00');
        this.formConfig.get(`endTimeSecondPeriod`)!.setValue('00:00');
    } else {
        this.journeyType = 'day';
        this.formJourneyDayStatus = false;
    }
  }

  public openSelectCompany() {
    this.reloadSelectComponent();
    this.displaySelectDialog = true;
  }

  async selectValues(value: any): Promise<void>  {
    const {companyId, branchId, path} = value;
    let type = '';

    if (!branchId === false) {
        const selectedCompany = await this.companyService.get(branchId).toPromise();
        type! = selectedCompany?.data?.type!;
        this.companyName = selectedCompany?.data!.name
        await this.getDepartments(branchId);
    }

    if (!branchId === true) {
        const selectedCompany = await this.companyService.get(companyId).toPromise();
        type! = selectedCompany?.data?.type!;
        this.companyName = selectedCompany?.data!.name
        await this.getDepartments(companyId);
    }

    this.thereIsBranc = await this.companyService.thereIsBranch(companyId, type);
        if (companyId !== '' && branchId !== '') {
            this.companyIdTemp = branchId;
            this.pathTemp = path;
        } else {
            this.companyIdTemp = companyId;
            this.pathTemp = path;
        }
      this.formConfig?.get('departmentId')?.setValue('');
  }

  public async confirmSelectValues() {
    this.formConfig.get('departmentId')?.setValue('');
    this.formConfig.get('companyId')?.setValue(this.companyIdTemp);
    this.formConfig.get('path')?.setValue(this.pathTemp);
    this.getDepartments(this.companyIdTemp);
    this.displaySelectDialog = false;
  }

  public async onCancelSelectValues() {
    this.displaySelectDialog = false;
    this.reloadSelectComponent();
    const company = this.company;
    this.companyName = this.company.name
    this.thereIsBranc = await this.companyService.thereIsBranch(company.id!, company.type!);
  }

  public reloadSelectComponent() {
    this.reloaddSelectComponent = false;
    setTimeout(() => {
        this.reloaddSelectComponent = true
    }, 100);
  }

  async getDepartments(companyId: string) {
    this.departments = [];
    this.formConfig?.get('departmentId')?.disable();
    try {
        this.departmentService.list(companyId).subscribe( department => {
            const result: Department[] = [];
            department?.data?.map((department: Department) => {
                if (department.status === 'ACTIVE') {
                    result.push(department);
                }
            });

            this.departments = result;
            if (this.departments.length === 0) {
                this.formConfig?.get('departmentId')?.disable();
            } else {
                this.formConfig?.get('departmentId')?.enable();
            }
        });
    } catch (error: any) {}
  }

  async getCurrentDepartmentName(companyId: string) {
    try {
        this.departmentService.list(companyId).subscribe( department => {
            const filterResult = department.data?.filter((department: any) => {
                return department.id === this.formConfig.get('departmentId')?.value
            })
            filterResult?.map((departmentName: any) => this.departmentName = departmentName.name);
        });
    } catch (error: any) {}
  }

  private async getCompanyPosition(companyId: string): Promise<void> {
    const response: HttpCompanyPositionResponse | undefined = await this.companyPositionService.list (
        companyId
    ).toPromise() || undefined;

    if (response?.data?.length! > 0) {
        response?.data?.map((companyPosition: any) => {
            this.companiesPositions?.push(companyPosition);
        });
        this.companiesPositions?.find((f: CompanyPosition) => {
            if (f.id === this.contract.companyPositionId) {
                this.companiesPositionSelect = f.name;
            }
        })?.name;
        this.loadSkeleton = false;
    }
  }

  async setDayOfWeek(flag: any) {
    const field = this.formConfig.get(flag);
    field?.setValue(!field.value, { onlySelf: true, emitEvent: true });
  }

  async updateContract() {
    try {
      const contractId = this.contract!.id;
      const contractValues = {...this.contract, ...this.formConfig.value};
      const employee = {...this.employee}

        let journeyData: any;

        if (this.journeyType === 'week') {
            journeyData = JSON.stringify(this.journeyByWeek)
        } else {
            journeyData = JSON.stringify(this.journeyByDay)
        }

      delete employee.contract;

      contractValues.role = this.formConfig.get('role')?.value;
      contractValues.companyId = this.formConfig.get('companyId')?.value;
      contractValues.departmentId = this.formConfig.get('departmentId')?.value;
      contractValues.branchAddress = this.formConfig.get('branchAddress')?.value === 'true';
      contractValues.homeOffice =  this.formConfig.get('homeOffice')?.value === 'true';
      contractValues.flexibleJourney = this.formConfig.get('flexibleJourney')?.value === 'true';
      contractValues.headOffice = this.formConfig.get('headOffice')?.value === 'true';
      contractValues.skipAllValidation = this.formConfig.get('skipAllValidation')?.value === 'true';
      contractValues.journeyType = this.journeyType,
      contractValues.journey = journeyData,

      delete contractValues.employee;
      delete contractValues.company;

      const isValidDates = true;

      if (isValidDates) {
        this.contractService.updateContract(contractId!, contractValues).subscribe((response: any) => {
          if (response.success) {
            this.updateEmployee(employee, contractValues.role, response.data.path, contractValues.companyId, contractValues.departmentId);
            setTimeout(() => {
              this.onCancel();
            }, 2000);
            this.messageService.add({
              severity: 'success',
              summary: 'Configurações atualizadas!'
            });
            this.store.dispatch(EmployeeAction.clearEmployeeStateAction());
          } else {
            this.openAlert('Error', response.mensagem);
          }
        });
      } else {
        this.openAlert('Error', 'Períodos incorretos');
      }
    } catch (error) {
        console.log(error);
    }
  }

  public async updateEmployee(employee: any, role: any, path: any, companyId: any, departmentId: any) {
    const data = { ...employee, role, path, companyId, departmentId};

    await this.employeeService.update(employee.id, data).toPromise();
  }

  public organizedWeekdays(): void {
    const selectedValues = [];

    if (this.contract?.enabledMonday) {
      selectedValues.push('Segunda');
    }

    if (this.contract?.enabledTuesday) {
      selectedValues.push('Terça');
    }

    if (this.contract?.enabledWednesday) {
      selectedValues.push('Quarta');
    }

    if (this.contract?.enabledThursday) {
      selectedValues.push('Quinta');
    }

    if (this.contract?.enabledFriday) {
      selectedValues.push('Sexta');
    }

    if (this.contract?.enabledSaturday) {
      selectedValues.push('Sábado');
    }

    if (this.contract?.enabledSunday) {
      selectedValues.push('Domingo');
    }

    this.workDays = Object.assign(selectedValues);
  }

  onEditingConfigureEmployee(): void {
    this.editingEmployeeConfiguration = true;
  }

  public async homeOfficeToggleChange(event: any) {

    const flexibleJourney  = this.formConfig.get('flexibleJourney');
    const headOffice  = this.formConfig.get('headOffice');
    const branchAddress  = this.formConfig.get('branchAddress');
    const skipAllValidation  = this.formConfig.get('skipAllValidation');

    flexibleJourney?.setValue(false);
    headOffice?.setValue(false);
    branchAddress?.setValue(false);
    skipAllValidation?.setValue(false);

    EventEmitterService.get(EventEmitterService.HEAD_OFFICE_EVENT_CHECKED).emit(false);
  }

  public async flexibleJourneyToggleChange(event: any) {

    const homeOffice  = this.formConfig.get('homeOffice');
    const headOffice  = this.formConfig.get('headOffice');
    const branchAddress  = this.formConfig.get('branchAddress');
    const skipAllValidation  = this.formConfig.get('skipAllValidation');

    EventEmitterService.get(EventEmitterService.HEAD_OFFICE_EVENT_CHECKED).emit(true);

    homeOffice?.setValue(false);
    headOffice?.setValue(false);
    branchAddress?.setValue(false);
    skipAllValidation?.setValue(false);
  }

  public async headOfficeToggleChange(event: any) {

    const homeOffice  = this.formConfig.get('homeOffice');
    const flexibleJourney  = this.formConfig.get('flexibleJourney');
    const branchAddress  = this.formConfig.get('branchAddress');
    const skipAllValidation  = this.formConfig.get('skipAllValidation');

    homeOffice?.setValue(false);
    flexibleJourney?.setValue(false);
    branchAddress?.setValue(false);
    skipAllValidation?.setValue(false);

    EventEmitterService.get(EventEmitterService.HEAD_OFFICE_EVENT_CHECKED).emit(false);
  }

  public async branchAddressToggleChange(event: any) {

    const homeOffice  = this.formConfig.get('homeOffice');
    const flexibleJourney  = this.formConfig.get('flexibleJourney');
    const headOffice  = this.formConfig.get('headOffice');
    const skipAllValidation  = this.formConfig.get('skipAllValidation');

    homeOffice?.setValue(false);
    flexibleJourney?.setValue(false);
    headOffice?.setValue(false);
    skipAllValidation?.setValue(false);

    EventEmitterService.get(EventEmitterService.HEAD_OFFICE_EVENT_CHECKED).emit(true);
  }

  public async skipAllValidationToggleChange(event: any) {

    this.confirmationService.confirm({
        message: `
                Essa ação irá desabilitar as regras de registro de ponto e seu colaborador
                poderá registra-lo sem limitação de local e horário, cabendo ao gestor fazer a
                conferência usando o histórico e o banco de horas.
            `,
        header: 'Atenção',
        accept: () => {
            const homeOffice  = this.formConfig.get('homeOffice');
            const flexibleJourney  = this.formConfig.get('flexibleJourney');
            const headOffice  = this.formConfig.get('headOffice');
            const branchAddress  = this.formConfig.get('branchAddress');

            homeOffice?.setValue(false);
            flexibleJourney?.setValue(false);
            headOffice?.setValue(false);
            branchAddress?.setValue(false);
            EventEmitterService.get(EventEmitterService.HEAD_OFFICE_EVENT_CHECKED).emit(true);
        },
        reject: () => {
            const skipAllValidation  = this.formConfig.get('skipAllValidation');
            skipAllValidation?.setValue(false);
        }
    });

  }

   updateJoruneyDayFormStatus(value: any) {
    this.formJourneyDayStatus = value;
   }

   public changeRole(event: any): any {
    return event.checked;
   }

   public onChange($event: any){
    let text = $event.target.options[$event.target.options.selectedIndex].text;
    this.companiesPositionSelect = text;
   }

    public onCancel() {
      this.editingEmployeeConfiguration = false;
      this.reloadContract();
      this.organizedWeekdays();
      this.cancelUpdate.emit(true);
      this.ngOnInit();
    }

    private async reloadContract() {
        const employeeId = this.contract.employeeId;
        const reloadContract = await this.contractService.getContractsByEmployee(employeeId).toPromise();
        this.contract = Object.assign(reloadContract!.data[0]);
        this.organizedWeekdays();
    }

    updatePeriodsValidation(enabled: boolean) {
        const fieldsToValidate = [
          'startTimeFirstPeriod',
          'endTimeFirstPeriod',
          'startTimeSecondPeriod',
          'endTimeSecondPeriod',
        ];

        this.updateValidation(fieldsToValidate, enabled);
    }

    updateValidation(fieldsToValidate: any[], enabled: boolean) {
        fieldsToValidate.forEach((field) => {
          this.formConfig.get(field)!.clearValidators();
          this.formConfig.get(field)!.updateValueAndValidity();
        });

        if (enabled) {
          fieldsToValidate.forEach((field) => {
            this.formConfig.get(field)!.setValidators([
                Validators.required,
                (control: AbstractControl) => {
                  const value = control.value;
                  if (value === '00:00') {
                    return { invalidTime: true };
                  }
                  return null;
                }
              ]);
              this.formConfig.get(field)!.updateValueAndValidity();
          });
        }

        if (!enabled) {
          fieldsToValidate.forEach((field) => {
            this.formConfig.get(field)!.setValue('00:00');
            this.formConfig.get(field)!.updateValueAndValidity();
          });
        }
    }

    showAttentionMessage() {
        // Verifique se algum dos campos atende às condições (00:00 ou vazio)
        const startTimeFirstPeriodValue = this.formConfig.get('startTimeFirstPeriod')!.value;
        const endTimeFirstPeriodValue = this.formConfig.get('endTimeFirstPeriod')!.value;
        const startTimeSecondPeriodValue = this.formConfig.get('startTimeSecondPeriod')!.value;
        const endTimeSecondPeriodValue = this.formConfig.get('endTimeSecondPeriod')!.value;

        return (
          startTimeFirstPeriodValue === '00:00' || startTimeFirstPeriodValue === '' ||
          endTimeFirstPeriodValue === '00:00' || endTimeFirstPeriodValue === '' ||
          startTimeSecondPeriodValue === '00:00' || startTimeSecondPeriodValue === '' ||
          endTimeSecondPeriodValue === '00:00' || endTimeSecondPeriodValue === ''
        );
    }

    checkIfOneAnableExists() {
        if (
            this.formConfig.get('enabledMonday')!.value === false &&
            this.formConfig.get('enabledTuesday')!.value === false &&
            this.formConfig.get('enabledWednesday')!.value === false &&
            this.formConfig.get('enabledThursday')!.value === false &&
            this.formConfig.get('enabledFriday')!.value === false &&
            this.formConfig.get('enabledSaturday')!.value === false &&
            this.formConfig.get('enabledSunday')!.value === false
        ) {
            this.formStatus = false;
            this.activeJourney = false;
        } else {
            this.formStatus = true;
            this.activeJourney = true;
        }

    }
}
